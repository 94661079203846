<div class="search-modal container-md">
  <div class="search-modal__search-container">
    <div class="search-modal__search-input-icon qs-icon qs-icon--search qs-icon--medium"></div>

    <input
      type="text"
      class="search-modal__search-input"
      placeholder="Search for a product, blog and topic..."
      (input)="handleSearch($event)">

    <button
      type="button"
      aria-label="Close modal"
      role="button"
      class="search-modal__search-input-icon qs-icon qs-icon--medium qs-icon--remove-circle"
      (click)="closeModal()">
    </button>
  </div>

  @if (!searchValue?.length && history$()?.hits?.length && !combinedResults$().length) {
    <div class="search-modal__results">
      @for (historyResult of [history$()]; track historyResult) {
        <div class="search-modal__result-col">
          <app-search-results
            [hits]="historyResult!"
            [isHistory]="true" />
        </div>
      }
    </div>
  }

  @if ((!searchValue?.length && !history$()?.hits?.length) || (!combinedResults$().length && searchValue?.length)) {
    <div class="search-modal__empty-placeholder">
      <div class="search-modal__empty-placeholder-img"></div>

      <p class="search-modal__empty-placeholder-title">
        No results
      </p>

      <p class="search-modal__empty-placeholder-desc">
        {{
          !searchValue?.length && !history$()?.hits?.length
            ? 'Start typing to see search results'
            : 'Try another search term'
        }}
      </p>
    </div>
  }

  @if (combinedResults$().length) {
    <div class="search-modal__results">
      @for (searchResult of combinedResults$(); track searchResult) {
        @if (searchResult?.hits?.length) {
          <div class="search-modal__result-col">
            <app-search-results [hits]="searchResult" />
          </div>
        }
      }
    </div>
  }
</div>

<div
  class="search-modal__curtain"
  (click)="closeModal()">
</div>
