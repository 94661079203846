import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  afterNextRender,
  inject,
  input,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { ESectionColor, ESectionCTAPosition } from '../components/sections/section.interface';
import {
  EDescriptionListJustify,
  EPillColor,
  EPillSize,
  IDescriptionListItemContent,
} from '../components/description-list-item/description-list-item.interface';
import { ICustomSectionContent } from '../components/sections/custom-section/custom-section.interface';
import { IHomeContent } from './home.interface';
import { IDataPayloadMultiple, IDataPayloadSingle, IStrapiData } from '../core/http/http.interface';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { EPrintSolution } from '../components/main-navigation/sub-menu/sub-menu-print-solutions/sub-menu-print-solutions.interface';
import { ISubHeroSectionContent } from '../components/sections/sub-hero-section/sub-hero-section.interface';
import { IProductViewContent } from '../products/print-solutions/product-view/product-view.interface';
import { SeoService } from '../core/seo/seo.service';
import { AbstractControl, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContactUsService } from '../contact-us/contact-us.service';
import { EContactType, IContactUsFormValue } from '../contact-us/contact-us.interface';
import { EAlertBoxType, IAlertBoxConfig } from '../components/alert-box/alert-box.interface';
import { GoogleAnalyticsService } from '../core/google-analytics/google-analytics.service';
import { ICTA } from '../components/cta/cta.interface';
import { IContent } from '../content/content.interface';
import { SubMenuContentService } from '../components/main-navigation/sub-menu/sub-menu-content/sub-menu-content.service';
import { IContentMenuItem } from '../components/main-navigation/main-navigation.interface';
import { SearchModalService } from '../components/search-modal/search-modal.service';
import { ContentCategoriesSectionComponent } from '../components/sections/content-categories-section/content-categories-section.component';
import { RouterModule } from '@angular/router';
import { AlertBoxComponent } from '../components/alert-box/alert-box.component';
import { ContactUsComponent } from '../components/contact-us/contact-us.component';
import { CtaComponent } from '../components/cta/cta.component';
import { FormatsComponent } from '../components/formats/formats.component';
import { SearchInputComponent } from '../components/search-input/search-input.component';
import { SubHeroSectionComponent } from '../components/sections/sub-hero-section/sub-hero-section.component';
import { CustomSectionComponent } from '../components/sections/custom-section/custom-section.component';
import { SafePipe } from '../core/safe/safe.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    SafePipe,
    ReactiveFormsModule,
    SearchInputComponent,
    CtaComponent,
    FormatsComponent,
    ContentCategoriesSectionComponent,
    AlertBoxComponent,
    ContactUsComponent,
    SubHeroSectionComponent,
    CustomSectionComponent,
    NgTemplateOutlet,
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  protected readonly _destroy$: Subject<boolean> = new Subject<boolean>();
  protected readonly _subMenuContentService: SubMenuContentService = inject(SubMenuContentService);
  protected readonly _seoService: SeoService = inject(SeoService);
  protected readonly _formBuilder: FormBuilder = inject(FormBuilder);
  protected readonly _contactUsService: ContactUsService = inject(ContactUsService);
  protected readonly _ref: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly _analyticsService: GoogleAnalyticsService = inject(GoogleAnalyticsService);
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);

  @ViewChild('videoPlayer') public videoPlayer!: ElementRef;

  public readonly sectionColors: typeof ESectionColor = ESectionColor;
  public content$ = input.required<IDataPayloadSingle<IHomeContent>>();
  public guides$ = input.required<IDataPayloadMultiple<IContent>>();
  public industries$ = input.required<IDataPayloadMultiple<IContentMenuItem>>();
  public militaries$ = input.required<IDataPayloadMultiple<IContentMenuItem>>();
  public quickConnectSectionContent!: ISubHeroSectionContent;
  public printProductSectionContent!: ICustomSectionContent;
  public printProducts1!: IDescriptionListItemContent[];
  public printProducts2!: IDescriptionListItemContent[];
  public newsLetterSectionContent!: ICustomSectionContent;
  public newsLetterCtas!: ICTA[];
  public isSuccessfullySubmitted: boolean | null = null;
  public alertBoxConfig!: Partial<IAlertBoxConfig>;
  public alertBoxType: typeof EAlertBoxType = EAlertBoxType;
  public form = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    newsletterOptIn: [true],
  });

  get email(): AbstractControl<string | null, string | null> | null {
    return this.form.get('email');
  }

  get newsletterOptIn(): AbstractControl<boolean | null, boolean | null> | null {
    return this.form.get('newsletterOptIn');
  }

  constructor() {
    afterNextRender(() => {
      this.videoPlayer.nativeElement.muted = true;
      this.videoPlayer.nativeElement.play()
        .catch(() => {
          this.videoPlayer.nativeElement.play()
        });
    });
  }

  public ngOnInit(): void {
    this._setHomePageContentSuccess();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  private _setHomePageContentSuccess(): void {
    const {
      quickConnectWitty,
      quickConnectTitle,
      quickConnectDescription,
      quickConnectImage,
      quickConnectCta,
      printWitty,
      newsletterWitty,
      newsletterCta,
      print_products,
      seo,
    } = this.content$().data?.attributes!;

    this.quickConnectSectionContent = {
      witty: quickConnectWitty,
      title: quickConnectTitle,
      description: quickConnectDescription,
      image: quickConnectImage,
      ctas: quickConnectCta,
      offset: false,
      showTopTriangle: true,
      sectionColor: {
        color: ESectionColor.GRAY_BLUE,
      },
    };

    this.printProductSectionContent = {
      witty: printWitty,
      sectionColor: {
        color: ESectionColor.DARK_BLUE,
      },
      showTopTriangle: true,
      bottomPadding: true,
      ctaPosition: ESectionCTAPosition.TOP,
      offset: false,
    };

    this.newsLetterSectionContent = {
      witty: newsletterWitty,
      sectionColor: {
        color: ESectionColor.GRAY_BLUE,
      },
      showTopTriangle: true,
      bottomPadding: true,
      offset: false,
    };

    this.newsLetterCtas = newsletterCta;

    if (print_products.data) {
      // We copy this data because otherwise, it is processed twice (the backend, and the front-end)
      // thus shortening the list of products listed.
      const printProducts = JSON.parse(JSON.stringify(print_products));
      const printProducts1 = printProducts.data.splice(0, 2);
      const printProducts2 = printProducts.data;

      // The products needs to be split in 2 because of layout issues
      this.printProducts1 = printProducts1.map(({ attributes }: IStrapiData<IProductViewContent>) => ({
        title: attributes.title,
        description: attributes.subTitle,
        slug: attributes.slug,
        ctas: attributes.productListCtas,
        icon: attributes.icon,
        justify: EDescriptionListJustify.LEFT,
        color: ESectionColor.DARK_BLUE,
      }));

      this._addPopularPillToPGuide(this.printProducts1);

      this.printProducts2 = printProducts2.map(({ attributes }: IStrapiData<IProductViewContent>) => ({
        title: attributes.title,
        description: attributes.subTitle,
        slug: attributes.slug,
        ctas: attributes.productListCtas,
        icon: attributes.icon,
        justify: EDescriptionListJustify.LEFT,
        color: ESectionColor.DARK_BLUE
      }));
    }

    this._seoService.setSeoTags(seo);
  }

  private _addPopularPillToPGuide(data: IDescriptionListItemContent[]): void {
    // Add "Popular" pill to the pocket guide product
    data.forEach((product) => {
      if (product.slug === EPrintSolution.POCKET_GUIDES) {
        product['pillLabel'] = 'Popular';
        product['pillColor'] = EPillColor.RED;
        product['pillSize'] = EPillSize.TINY;
      }
    });
  }

  public submit(): void {
    if (!this.form.valid) {
      return;
    }

    this.form.get('newsletterOptIn')?.setValue(true);

    this._contactUsService
      .postContactUsForm(
        { data: this.form.getRawValue() as unknown as IContactUsFormValue },
        EContactType.NEWSLETTER,
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: this._submitOnSuccess.bind(this),
        error: this._submitOnError.bind(this),
      });
  }

  private _submitOnSuccess(): void {
    this.isSuccessfullySubmitted = true;
    this.alertBoxConfig = {
      title: 'Success',
      message: `
        Thank you for subscribing to our newsletter. Have a great day!
      `,
    };

    this._clearForm();
    this._analyticsService.event('generate_lead');
    this._ref.detectChanges();
  }

  private _submitOnError(error: any): void {
    this.isSuccessfullySubmitted = false;
    this.alertBoxConfig = {
      title: 'Error',
      message: `
        An error occured while submitting your email. Please try submitting the form again.
      `,
    };

    console.log(error);
    this._ref.detectChanges();
  }

  private _clearForm() {
    const contacType: AbstractControl<EContactType | null, EContactType | null> | null = this.form.get('contacType');

    this.form.reset();

    contacType?.patchValue(EContactType.CONTACT_US);
  }

  public dismissAlertBox() {
    this.isSuccessfullySubmitted = null;
  }

  public play(event: any): void {
    event.target.playVideo();
  }

  public openSearchModel(): void {
    this._searchModalService.toggleSearchModal(true);
  }
}
