<div class="captcha-modal container-md">
  <div class="captcha-modal__content">
    <h4>Website Search Disabled</h4>

    <p>
      <strong>Attention:</strong> Unusual activity has been detected from your device or network. For our website's security, we need to confirm you're not a robot.
    </p>

    <p>
      <strong>Experiencing this message often?</strong> Try the following steps:
    </p>

    <ul>
      <li>Disable VPNs or proxies if in use, as they can be linked to high-risk activities.</li>
      <li>Clear your browser's cookies and cache.</li>
      <li>Switch to a different browser or device.</li>
    </ul>

    <p>
      <strong>Need further assistance?</strong> Please don't hesitate to contact us.
    </p>
  </div>

  <div class="captcha-modal__cta">
    <button
      class="qs-btn qs-btn--secondary"
      (click)="closeModal()"
      [routerLink]="'/contact-us'">

      Contact us
    </button>

    <button
      class="qs-btn qs-btn--primary"
      (click)="closeModal()">

      Ok
    </button>
  </div>
</div>

<div class="captcha-modal__curtain"></div>
