<app-main-navigation></app-main-navigation>

<main class="main">
  <router-outlet></router-outlet>
</main>

<footer>
  <app-footer></app-footer>
</footer>

@if (searchModalIsOpen()) {
  <app-search-modal></app-search-modal>
}

@if (captchaModalIsOpen()) {
  <app-captcha-modal></app-captcha-modal>
}
