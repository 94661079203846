import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ProductRedirectService } from './product-redirect.service';
import { ISearchResponse } from 'src/app/components/search-bar/search-bar.interface';
import { IContent } from 'src/app/content/content.interface';
import { IOutreach } from 'src/app/products/print-solutions/product-view/product-view.interface';

export const productRedirectResolver: ResolveFn<Promise<[IContent, IContent, IOutreach, ISearchResponse]>> = (route: ActivatedRouteSnapshot) => {
  return inject(ProductRedirectService).getProducts(route.params['slug']);
}
