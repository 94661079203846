<div class="sub-menu-print-solutions row">
  <div class="col-sm-12 col-md-6">
    <app-print-solutions-list
      [products]="col1"
      (click)="closeNavigation()" />
  </div>

  <div class="col-sm-12 col-md-6">
    <app-print-solutions-list
      [products]="col2"
      (click)="closeNavigation()" />

    <div class="sub-menu-print-solutions__personalize">
      <img
        class="sub-menu-print-solutions__personalize-img"
        src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/p_Guide_customization1_3_21ade031cd.webp"
        alt="The cover image of a custom QuickSeries pocket guide."
        width="312"
        height="465"
        loading="lazy">

      <div class="sub-menu-print-solutions__personalize-info">
        <p class="sub-menu-print-solutions__personalize-title">
          Customize Any QuickSeries Product
        </p>

        <p class="sub-menu-print-solutions__personalize-desc">
          Target your audience's specific interests with custom imagery and/or
          content, improving retention and ensuring engagement with your people.
        </p>

        <a
          class="sub-menu-print-solutions__personalize-link"
          [routerLink]="'/products/print-solutions-outreach-material'"
          [fragment]="'custom-print'"
          (click)="closeNavigation()">

          Find out more

          <span class="qs-icon qs-icon--href-arrow-right qs-icon--tiny"></span>
        </a>
      </div>
    </div>
  </div>

</div>
