import { Component, Input } from '@angular/core';
import { IPrintSolution } from '../sub-menu-print-solutions.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-print-solutions-list',
  templateUrl: './print-solutions-list.component.html',
  styleUrl: './print-solutions-list.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class PrintSolutionsListComponent {
  @Input() products?: IPrintSolution[];
}
