<div class="super-navigation">
  <div class="container-fluid super-navigation__container">
    <a
      routerLink="/products/quickconnect-app-solutions/smartphone-app-accesibility-design"
      class="super-navigation__link">

      VPAT 508
    </a>
    <a
      routerLink="/quickseries-gsa-schedule"
      class="super-navigation__link">

      GSA Info
    </a>
    <a
      routerLink="/contact-us"
      class="super-navigation__link">

      Contact Us
    </a>
  </div>
</div>

<div class="main-navigation">
  <div class="container-fluid main-navigation__main-nav">
    <button
      type="button"
      class="main-navigation__menu qs-icon qs-icon--hamburger qs-icon--medium"
      (click)="toggleNavigation(navIsOpen$())">

      Menu
    </button>

    <a
      [routerLink]="['/']"
      class="main-navigation__logo">

      <img
        src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/QS_Logo_Red_e287b3fd32.svg"
        alt="Logo for QuickSeries Publishing - Reliable Content. Innovative Delivery"
        loading="eager"
        width="70"
        height="40"
        loading="lazy">
    </a>

    <nav
      class="main-navigation__links"
      [ngClass]="{
        'main-navigation__links--is-open': navIsOpen$()
      }">

      <div class="main-navigation__mobile-header">
        <a
          [routerLink]="['/']"
          class="main-navigation__logo main-navigation__logo--mobile">

          <img
            src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/QS_Logo_Red_e287b3fd32.svg"
            alt="Logo for QuickSeries Publishing - Reliable Content. Innovative Delivery"
            loading="eager"
            width="70"
            height="40"
            loading="lazy">
        </a>

        <button
          type="button"
          class="main-navigation__close qs-icon qs-icon--close"
          (click)="toggleNavigation(navIsOpen$())">

          Close
        </button>
      </div>

      @for (link of mainNavLinks | keyvalue : sortMainNavLinks; track $index) {
        @if (link.value.subMenu) {
          <button
            type="button"
            class="main-navigation__link main-navigation__link--with-sub-menu"
            aria-haspopup="true"
            [class.main-navigation__link--active]="isCurrentPath(link.value)"
            [attr.aria-expanded]="activeSubMenu !== null"
            (click)="toggleSubNav(link.key)">

            {{ link.value.label }}
          </button>
        } @else {
          <a
            class="main-navigation__link"
            [routerLink]="link.value.routerLink"
            [routerLinkActive]="'main-navigation__link--active'"
            (click)="toggleNavigation(true)">

            {{ link.value.label }}
          </a>
        }
      }

      <button
        type="button"
        class="main-navigation__link main-navigation__link--search-btn qs-icon qs-icon--search qs-icon--small"
        aria-haspopup="true"
        (click)="openSearchModal()">

        Search
      </button>

      <app-search-input
        class="main-navigation__link main-navigation__link--search-input"
        aria-haspopup="true">
      </app-search-input>
    </nav>
  </div>
</div>

<div class="main-navigation__sub-menus">
  @if (mainNavLinks['content'].isOpen) {
    <app-sub-menu
      title="Content"
      [attr.aria-hidden]="!mainNavLinks['content'].isOpen"
      [links]="contentNav"
      [type]="ESubNav.CONTENT">

      <p description>
        QuickSeries content is unparalleled in its reliability, accuracy, and
        comprehensiveness. Our skilled editing team ensures that our material
        reflects the latest industry standards and best practices.
      </p>

      <app-sub-menu-content />
    </app-sub-menu>
  }

  @if (mainNavLinks['printSolutions'].isOpen) {
    <app-sub-menu
      title="Print Solutions"
      [attr.aria-hidden]="!mainNavLinks['printSolutions'].isOpen"
      [links]="printSolutionsNav"
      [type]="ESubNav.PRINT_SOLUTIONS">

      <p description>
        Maximize the impact of your message with our expertise in modern design,
        formatting, and multilingual delivery. Our print solutions are tailored
        to your distinct needs, ensuring that you leave a lasting impression on
        your audience.
      </p>

      <app-sub-menu-print-solutions />
    </app-sub-menu>
  }

  @if (mainNavLinks['quickConnectAppSolutions'].isOpen) {
    <app-sub-menu
      title="QuickConnect App Solutions"
      [attr.aria-hidden]="!mainNavLinks['quickConnectAppSolutions'].isOpen"
      [links]="appSolutionsNav"
      [type]="ESubNav.APP_SOLUTIONS">

      <p description>
        Do not settle for a generic app when you can deliver an immersive mobile
        experience for your users! With 20+ customizable features and a
        user-friendly, web-based portal for easy control, your QuickConnect App
        Solution can be ready to launch in just a few weeks.
      </p>

      <app-sub-menu-app-solutions />
    </app-sub-menu>
  }

  @if (mainNavLinks['whiteboardVideos'].isOpen) {
    <app-sub-menu
      title="Whiteboard Videos"
      [attr.aria-hidden]="!mainNavLinks['whiteboardVideos'].isOpen"
      [links]="whiteboardVideoNav"
      [type]="ESubNav.WHITEBOARD_VIDEOS">

      <p description>
        Whiteboard videos, with their animated flair and clear language, break
        down complex ideas, concepts, and processes in a compelling way.
      </p>

      <app-sub-menu-whiteboard-video />
    </app-sub-menu>
  }

  @if (mainNavLinks['quickBlog'].isOpen) {
    <app-sub-menu
      title="QuickBlog"
      [attr.aria-hidden]="!mainNavLinks['quickBlog'].isOpen"
      [links]="quickBlogNav"
      [type]="ESubNav.QUICKBLOG">

      <p description>
        Our QuickBlog is your go-to source for credible content, eye-catching
        infographics, and other valuable resources. Whether you are searching for
        something specific or just browsing, our blogs offer a wealth of
        information on a wide range of topics.
      </p>

      <app-sub-menu-quickblog />
    </app-sub-menu>
  }
</div>
