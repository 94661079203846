import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { guidesResolver, homeResolver, industriesResolver, militariesResolver, searchResolver } from './app.resolver';
import { HomeComponent } from './home/home.component';
import { productRedirectResolver } from './redirects/product-redirect/product-redirect.resolver';
import { blogRedirectResolver } from './redirects/blog-redirect/blog-redirect.resolver';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'QuickSeries - Reliable Content. Innovative Delivery.',
    resolve: {
      content$: homeResolver,
      industries$: industriesResolver,
      militaries$: militariesResolver,
      guides$: guidesResolver,
    },
  },

  {
    path: 'content',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  },

  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'products/:slug',
    loadComponent: () => import('./redirects/product-redirect/product-redirect.component').then(c => c.ProductRedirectComponent),
    resolve: {
      data: productRedirectResolver,
    },
  },

  {
    path: 'quick-blog-infographics-trends',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
  },

  {
    path: 'about-quickseries',
    loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
  },

  {
    path: 'quickseries-gsa-schedule',
    loadChildren: () => import('./gsa-info/gsa-info.module').then(m => m.GSAInfoModule),
  },

  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
  },

  {
    path: 'legal-policies',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule),
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'qs-community-board',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'qs-community-board/:slug',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
    resolve: {
      data: blogRedirectResolver,
    },
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'quickblog',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'quickblog/:slug',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
    resolve: {
      data: blogRedirectResolver,
    },
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'newsletter',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
  },

  // Route containing logic to redirect the user to the correct page
  // and signal to Google that the page was permenantly moved.
  {
    path: 'newsletter/:slug',
    loadComponent: () => import('./redirects/blog-redirect/blog-redirect.component').then(c => c.BlogRedirectComponent),
    resolve: {
      data: blogRedirectResolver,
    },
  },

  {
    path: '404',
    runGuardsAndResolvers: 'always',
    loadComponent: () => import('./page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent),
    resolve: {
      searchResults: searchResolver,
    },
  },

  {
    path: '410',
    runGuardsAndResolvers: 'always',
    loadComponent: () => import('./page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent),
    resolve: {
      searchResults: searchResolver,
    },
  },

  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
