import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { ISearchResponse } from 'src/app/components/search-bar/search-bar.interface';
import { SearchBarService } from 'src/app/components/search-bar/search-bar.service';
import { IContent } from 'src/app/content/content.interface';
import { ContentService } from 'src/app/content/content.service';
import { QsHttpService } from 'src/app/core/http/qs-http.service';
import { PrintSolutionsService } from 'src/app/products/print-solutions/print-solutions.service';
import { IOutreach } from 'src/app/products/print-solutions/product-view/product-view.interface';

@Injectable({ providedIn: 'root' })
export class ProductRedirectService {
  protected readonly _qsHttpClient: QsHttpService = inject(QsHttpService);
  protected readonly _contentService: ContentService = inject(ContentService);
  protected readonly _printSolutionsService: PrintSolutionsService = inject(PrintSolutionsService);
  protected readonly _searchBarService: SearchBarService = inject(SearchBarService);

  public getProducts(slug: string): Promise<[IContent, IContent, IOutreach, ISearchResponse]> {
    const queries: [
      Promise<IContent>,
      Promise<IContent>,
      Promise<IOutreach>,
      Promise<ISearchResponse>
    ] = [
      firstValueFrom(this._contentService.getContent(slug)),
      firstValueFrom(this._contentService.getContent(`${slug}-pocket-guide`)),
      firstValueFrom(this._printSolutionsService.getOutreach(slug)),
      this._searchBarService.globalSearch(slug),
    ];

    return Promise.all(queries);
  }
}
