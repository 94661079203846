<div class="sub-menu-content">
  <ng-content />

  <div class="sub-menu-content__link-group">
    <div>
      <p class="sub-menu-content__group-label">
        Industries
      </p>

      @if (industries$ | async; as industries) {
        <ul class="sub-menu-content__links">
          @for (link of industries.data; track link.attributes.uuid) {
            <li>
              <a
                class="sub-menu-content__link"
                [routerLink]="['/', 'content', 'industry', link.attributes.slug]"
                [queryParams]="{ sortOrder: sortOrder.FEATURED }"
                [routerLinkActive]="'sub-menu-content__link--is-active'"
                (click)="closeNavigation()">

                {{ link.attributes.name }}
              </a>
            </li>
          }
        </ul>
      }
    </div>
  </div>

  <div class="sub-menu-content__link-group">
    <p class="sub-menu-content__group-label">
      Military
    </p>

    @if (militaries$ | async; as militaries) {
      <ul class="sub-menu-content__links">
        @for (link of militaries.data; track link.attributes.uuid) {
          <li>
            <a
              class="sub-menu-content__link"
              [routerLink]="['/', 'content', 'military', link.attributes.slug]"
              [queryParams]="{ sortOrder: sortOrder.FEATURED }"
              [routerLinkActive]="'sub-menu-content__link--is-active'"
              (click)="closeNavigation()">

              {{ link.attributes.name }}
            </a>
          </li>
        }
      </ul>
    }
  </div>

  <div class="sub-menu-content__link-group">
    <p class="sub-menu-content__group-label">
      New & Trending
    </p>

    @if (guides$ | async; as guides) {
      <ul class="sub-menu-content__new-guides no-list">
        @for (guide of guides.data; track guide.attributes.UUID) {
          <li>
            <app-content-title-item
              [content]="guide.attributes"
              [color]="color.WHITE_BLUE"
              (click)="closeNavigation()" />
          </li>
        }
      </ul>
    }
  </div>
</div>
