import { Component, OnInit, inject } from '@angular/core';
import { EPrintSolution, IPrintSolution } from './sub-menu-print-solutions.interface';
import { MainNavigationService } from '../../main-navigation.service';
import { RouterModule } from '@angular/router';
import { PrintSolutionsListComponent } from './print-solutions-list/print-solutions-list.component';

@Component({
  selector: 'app-sub-menu-print-solutions',
  templateUrl: './sub-menu-print-solutions.component.html',
  styleUrl: './sub-menu-print-solutions.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    PrintSolutionsListComponent,
  ],
})
export class SubMenuPrintSolutionsComponent implements OnInit {
  private readonly _mainNavigationService: MainNavigationService = inject(MainNavigationService);

  public printProducts: IPrintSolution[] = [
    {
      type: EPrintSolution.POCKET_GUIDES,
      name: 'Pocket Guides',
      description: 'Our pocket guides keep vital information at your fingertips.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_8b486ce321.webp',
            alternativeText: 'A QuickSeries pocket guide cover.',
            width: 700,
            height: 1046,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.POCKET_GUIDES}`,
    },
    {
      type: EPrintSolution.DESK_GUIDES,
      name: 'Desk Guides',
      description: 'Make a bigger impact with our desk guides.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_0e9fb45ee6.webp',
            alternativeText: 'The QuickSeries Pocket Card front panel titled Cannabidiol (CBD) Get the Facts.',
            width: 766,
            height: 1052,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.DESK_GUIDES}`,
    },
    {
      type: EPrintSolution.POCKET_CARDS,
      name: 'Pocket Cards',
      description: 'Multiple panels offer more space for your message.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_dd9050b356.webp',
            alternativeText: 'Front panel of the Interview preparedness for service members quickseries 8 panel wallet card with tips on what to bring and how to dress for the interview.',
            width: 664,
            height: 976,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.POCKET_CARDS}`,
    },
    {
      type: EPrintSolution.WALLET_CARDS,
      name: 'Wallet Cards',
      description: 'Wallet cards offer flexibility and promote topic awareness.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_96f203524b.webp',
            alternativeText: 'The cover of a customized QuickSeries desk guide by Eastern Michigan University titled Survivor handbook with a photo of trees.',
            width: 474,
            height: 1216,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.WALLET_CARDS}`,
    },
    {
      type: EPrintSolution.POSTERS,
      name: 'Posters',
      description: 'Provide informative content with an eye-catching design.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_6cbb9ab4d8.webp',
            alternativeText: 'A dynamic layout of the quickseries trifold pamphlet titled Time to quit helping people stop smoking.',
            width: 726,
            height: 996,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.POSTERS}`,
    },
    {
      type: EPrintSolution.BADGE_CARDS_MAGNETS,
      name: 'Badge Cards & Magnets',
      description: 'Badge cards & magnets provide a visual reminder of your message.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_80b704a7bf.webp',
            alternativeText: 'One side of a QuickSeries double sided hurricane preparedness timeline poster with an infographic style detailing a timeline plan.',
            width: 650,
            height: 1024,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.BADGE_CARDS_MAGNETS}`,
    },
    {
      type: EPrintSolution.PAMPHLETS,
      name: 'Pamphlets',
      description: 'Reach your audience with the most familiar outreach format.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_352da169cc.webp',
            alternativeText: 'A quickseries magnet titled beat high blood pressure showing icons on how to be more heart healthy.',
            width: 550,
            height: 1360,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.PAMPHLETS}`,
    },
    {
      type: EPrintSolution.FOLDERS,
      name: 'Custom Folders',
      description: 'Our custom folders make premium takeaways.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_92770d84d4.webp',
            alternativeText: 'The month of September of the Veterans Affairs personalized content calendar detailing support for veterans and service members while showing monthly observances.',
            width: 757,
            height: 1232,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.FOLDERS}`,
    },
    {
      type: EPrintSolution.CALENDARS,
      name: 'Calendars',
      description: 'Reinforce your message all year long.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/thumbnail_hero_696f4b2edc.webp',
            alternativeText: 'The U.S. army Garrison-Miami personalized quickseries folder with internal pockets cover showing the garrison.',
            width: 1177,
            height: 1076,
          },
        },
      },
      routerLink: `/products/print-solutions-outreach-material/${EPrintSolution.CALENDARS}`,
    },
  ];
  public col1?: IPrintSolution[] = [];
  public col2?: IPrintSolution[] = [];

  public ngOnInit(): void {
    this.printProducts.forEach(this.dispatchColumns.bind(this));
  }

  private dispatchColumns(product: IPrintSolution, index: number): void {
    if (index < 6) {
      this.col1?.push(product);
      return;
    }

    this.col2?.push(product);
  }

  public closeNavigation(): void {
    this._mainNavigationService.toggleMainMenu(true);
    this._mainNavigationService.toggleSubNav(null);
  }
}
