<div class="sub-menu-quickblog">
  <p class="sub-menu-quickblog__title">
    recent articles
  </p>

  @if (blogs$ | async; as blogs) {
    <ul class="sub-menu-quickblog__list no-list row">
      @for (blog of blogs.data; track blog.attributes.UUID) {
        <li class="sub-menu-quickblog__blog-item col-md-6">
          <a
            class="sub-menu-quickblog__blog"
            [routerLink]="['/', 'quick-blog-infographics-trends', blog.attributes.slug]"
            [routerLinkActive]="'sub-menu-quickblog__blog--is-active'"
            (click)="closeNavigation()">

            @if (!blog.attributes.coverImage?.data) {
              <div class="sub-menu-quickblog__img-placeholder-container d-none d-sm-flex">
                <img
                  class="sub-menu-quickblog__img-placeholder"
                  loading="lazy"
                  src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/QS_Logo_Red_e287b3fd32.svg"
                  alt="QuickSeries logo"
                  width="70"
                  height="40">
              </div>
            }

            @if (blog.attributes.coverImage?.data) {
              <img
                class="sub-menu-quickblog__img d-none d-sm-block"
                loading="lazy"
                [src]="blog.attributes.coverImage?.data?.attributes?.formats?.small?.url"
                [width]="blog.attributes.coverImage?.data?.attributes?.formats?.thumbnail?.width"
                [height]="blog.attributes.coverImage?.data?.attributes?.formats?.thumbnail?.height"
                [alt]="blog.attributes.coverImage?.data?.attributes?.alternativeText">
            }

            <div>
              <p
                class="sub-menu-quickblog__blog-name"
                [innerHTML]="blog.attributes.title">
              </p>

              <p
                class="sub-menu-quickblog__blog-desc"
                [innerHTML]="truncateDescription(blog.attributes.body)">
              </p>
            </div>
          </a>
        </li>
      }
    </ul>
  }
</div>

