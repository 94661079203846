import { Component, inject, signal } from '@angular/core';
import { SearchModalService } from './search-modal.service';
import { SearchBarService } from '../search-bar/search-bar.service';
import { IHit, ISearchResponse, ISearchResult, ICombinedResults, ESearchCategory } from '../search-bar/search-bar.interface';
import { SearchResultsComponent } from './search-results/search-results.component';
import debounce from 'lodash.debounce';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrl: './search-modal.component.scss',
  standalone: true,
  imports: [
    SearchResultsComponent,
  ]
})
export class SearchModalComponent {
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);
  protected readonly _searchService: SearchBarService = inject(SearchBarService);

  public searchValue?: string;
  public combinedResults$ = signal<ICombinedResults[]>([]);
  public history$ = this._searchModalService.searchHistory$;

  public closeModal(): void {
    this._searchModalService.toggleSearchModal(false);
  }

  public handleSearch(event?: Event | null | undefined) {
    if (!event) {
      return;
    }

    this.searchValue = (event?.target as HTMLInputElement).value;

    if (this.searchValue === '') {
      this.combinedResults$.set([]);
      return;
    }

    return debounce(this._search.bind(this), 300)();
  }

  private async _search() {
    const { results }: ISearchResponse = await this._searchService.globalSearch(this.searchValue);
    let hasNoResults: boolean = true;

    results.forEach((result) => {
      if (result.hits.length) {
        hasNoResults = false;
      }
    });

    if (hasNoResults) {
      this.combinedResults$.set([]);
      return;
    }

    this.combineResults(results);
  }

  public combineResults(results: ISearchResult[]): void {
    const contentHits: IHit[] = [];
    const blogHits: IHit[] = [];
    const industryHits: IHit[] = [];
    const combinedResults: ICombinedResults[] = [];

    results.forEach((result) => {
      if (result.index === 'content' || result.index === 'outreach') {
        contentHits.push(...result.hits);
      }

      if (result.index === 'blog') {
        blogHits.push(...result.hits);
      }

      if (result.index === 'military' || result.index === 'industry') {
        industryHits.push(...result.hits);
      }
    });

    combinedResults.push(
      ({ label: ESearchCategory.CONTENT, hits: contentHits } as ICombinedResults),
      ({ label: ESearchCategory.INDUSTRIES, hits: industryHits } as ICombinedResults),
      ({ label: ESearchCategory.BLOGS, hits: blogHits } as ICombinedResults),
    );

    this.combinedResults$.set(combinedResults);
  }
}
