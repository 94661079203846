import { Component, inject } from '@angular/core';
import { INavLink } from '../../main-navigation.interface';
import { MainNavigationService } from '../../main-navigation.service';
import { EIcons } from 'src/app/components/sections/section.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sub-menu-app-solutions',
  templateUrl: './sub-menu-app-solutions.component.html',
  styleUrl: './sub-menu-app-solutions.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class SubMenuAppSolutionsComponent {
  private readonly _mainNavigationService: MainNavigationService = inject(MainNavigationService);

  public quickConnectLinks: INavLink[] = [
    {
      label: 'Explore QuickConnect',
      routerLink: '/products/quickconnect-app-solutions',
      subMenu: false,
      icon: EIcons.APP,
    },
    {
      label: 'Features',
      routerLink: '/products/quickconnect-app-solutions/features',
      subMenu: false,
      icon: EIcons.LIST_BULLETS,
    },
    {
      label: 'Rest API',
      routerLink: '/products/quickconnect-app-solutions/rest-api',
      subMenu: false,
      icon: EIcons.CLOUD_DATA_TRANSFER,
    },
    {
      label: 'eGuide Authoring Tool',
      routerLink: '/products/quickconnect-app-solutions/authoring-tool',
      subMenu: false,
      icon: EIcons.AUTHORING_TOOL_LOGO,
    },
    // {
    //   label: 'Our Work',
    //   routerLink: '/products/quickconnect-app-solutions/our-work',
    //   subMenu: false,
    //   icon: 'light-bulb-shine',
    // },
    {
      label: 'Our eGuides',
      routerLink: '/products/quickconnect-app-solutions/eguides',
      subMenu: false,
      icon: EIcons.PHONE_HAND_HOLD_1,
    },
    {
      label: 'White Papers',
      routerLink: '/products/quickconnect-app-solutions/app-question-white-papers',
      subMenu: false,
      icon: EIcons.NEWSPAPER,
    },
    {
      label: 'Become a Distributor',
      routerLink: '/products/quickconnect-app-solutions/distribute-quickseries-apps',
      subMenu: false,
      icon: EIcons.USER_NETWORK,
    },
    {
      label: 'Accessibility',
      routerLink: '/products/quickconnect-app-solutions/smartphone-app-accesibility-design',
      subMenu: false,
      icon: EIcons.UNIVERSAL_ACCESS,
    },
  ];
  public quickConnectBrands: INavLink[] = [
    {
      label: 'Emergency Preparedness',
      routerLink: '/products/quickconnect-app-solutions/brands/emergency-preparedness-mobile-app-unite',
      subMenu: false,
    },
    {
      label: 'Facility Safety',
      routerLink: '/products/quickconnect-app-solutions/brands/public-occupant-building-campus-safety',
      subMenu: false,
    },
    {
      label: 'Professional & Trade Associations',
      routerLink: '/products/quickconnect-app-solutions/brands/association-membership-smartphone-hub',
      subMenu: false,
    },
  ];

  public sellingPoints: any[] = [
    {
      text: '<strong>Quick Launch</strong> - Create and launch your native app within weeks.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/ready_to_launch_8f72908d17.svg',
            alternativeText: 'Icon representing a spaceship taking off',
            width: 62,
            height: 63,
          },
        },
      },
    },
    {
      text: '<strong>Affordable Technology</strong> - Starting at $6,000 per year, our apps can be scaled to any audience size.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/affordable_technology_e9fa0772d7.svg',
            alternativeText: 'An icon representing a price tag',
            width: 64,
            height: 61,
          },
        },
      },
    },
    {
      text: '<strong>Personalized User Experience</strong> - Craft a tailored experience for different user groups with access codes, user registration, and Workspaces.',
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/cater_diverse_groups_c96517ea98.svg',
            alternativeText: 'Icon representing a group of diverse individuals',
            width: 62,
            height: 57,
          },
        },
      },
    },
    {
      text: `<strong>Web-based Administrative Portal</strong> - Your app's portal mirrors your app, making updates simple with one click.`,
      image: {
        data: {
          attributes: {
            url: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/web_based_admin_portal_9571c589dc.svg',
            alternativeText: 'Icon representing a web browser running on a computer',
            width: 62,
            height: 65,
          },
        },
      },
    },
  ];

  public closeNavigation(): void {
    this._mainNavigationService.toggleMainMenu(true);
    this._mainNavigationService.toggleSubNav(null);
  }
}
