import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IDataPayloadMultiple } from 'src/app/core/http/http.interface';
import { IContentMenuItem } from '../main-navigation/main-navigation.interface';
import { SubMenuContentService } from '../main-navigation/sub-menu/sub-menu-content/sub-menu-content.service';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SafePipe } from 'src/app/core/safe/safe.pipe';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SafePipe,
  ],
})
export class FooterComponent implements OnInit, OnDestroy {
  protected readonly _subMenuContentService: SubMenuContentService = inject(SubMenuContentService);
  protected readonly _viewportScroller: ViewportScroller = inject(ViewportScroller);

  private readonly _destroy$: Subject<boolean> = new Subject<boolean>();

  public thisYear = new Date().getFullYear();
  public footerLinks$ = signal<any[]>([]);

  public ngOnInit(): void {
    this._fetchFooterLinks();
  }

  public ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  private _fetchFooterLinks() {
    forkJoin([
      this._subMenuContentService.getIndustries(),
      this._subMenuContentService.getMilitaries(),
    ])
    .pipe(takeUntil(this._destroy$))
    .subscribe(([industries, militaries]) => {
      this.footerLinks$.set([
        this._formatData(industries, '/content/industry', 'Industries'),
        this._formatData(militaries, '/content/military', 'Military'),
        {
          label: 'Our Solutions',
          links: [
            {
              label: 'Print',
              routerLink: 'products/print-solutions-outreach-material',
            },
            {
              label: 'App',
              routerLink: 'products/quickconnect-app-solutions',
            },
            {
              label: 'Whiteboard Videos',
              routerLink: 'products/whiteboard-video-creation',
            },
          ],
        },
        {
          label: 'Resources',
          links: [
            {
              label: 'QuickBlog',
              routerLink: 'quick-blog-infographics-trends',
            },
            {
              label: 'Accessibility by Design',
              routerLink: 'products/quickconnect-app-solutions/smartphone-app-accesibility-design',
            },
            {
              label: 'App Support',
              href: 'https://quickresource.quickseries.com/',
            },
            {
              label: 'Additional Services',
              href: 'https://quickresource.quickseries.com/additional-services/',
            }
          ],
        },
        {
          label: 'Company',
          links: [
            {
              label: 'About Us',
              routerLink: 'about-quickseries',
            },
            {
              label: 'GSA Info',
              routerLink: 'quickseries-gsa-schedule',
            },
            {
              label: 'Our Partners',
              routerLink: '/about-quickseries',
            },
            {
              label: 'Legal Policies',
              routerLink: 'legal-policies',
            },
            {
              label: 'Contact Us',
              routerLink: 'contact-us',
            },
          ],
        },
      ]);
    });
  }

  private _formatData(data: IDataPayloadMultiple<IContentMenuItem>, url: string, label: string) {
    if (!data?.data || data?.data?.length === 0) {
      return null;
    }

    const arr: { label: string; routerLink: string }[] = [];

    data.data.forEach((item) => {
      arr.push({
        label: item.attributes.name,
        routerLink: `${url}/${item.attributes.slug}`,
      });
    });

    return {
      label,
      links: arr,
    };
  }

  public goToAnchor(id: string | null | undefined): void {
    if (id) {
      this._viewportScroller.scrollToAnchor(id);
    }
  }
}
