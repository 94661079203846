<section class="section hero home">
  <div class="container-md">
    <div class="row align-items-center hero__section home__section">
      <div class="col-lg-6 col-md-7 hero__left">
        <img
          class="home__logo"
          src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/QS_Logo_Red_e287b3fd32.svg"
          alt="QuickSeries logo"
          width="152"
          height="85">

        <h1 class="hero__title">
          {{ content$().data?.attributes?.heroTitle }}
        </h1>

        <p class="hero__sub-title">
          {{ content$().data?.attributes?.heroSubTitle }}
        </p>

        <p class="hero__description">
          {{ content$().data?.attributes?.heroDescription }}
        </p>

        <app-search-input class="hero__search-field" />

        <div class="hero__product-sections">
          <div class="hero__product">
            <img
              class="hero__product-img"
              src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/Group_618_beedba83cf.webp"
              alt="A photo of a person holding a QuickSeries pocket guide."
              width="178"
              height="288"
              loading="lazy">

            <div class="hero__product-info">
              <p class="hero__product-title">Print Solutions</p>
              <p class="hero__product-desc">
                Our unique printed formats are designed to capture the reader's
                attention and deliver your message in a practical way.
              </p>

              <div class="hero__product-ctas">
                <a
                  routerLink="/products/print-solutions-outreach-material"
                  class="hero__product-cta qs-btn qs-btn--tiny qs-btn--primary">

                  Learn about Print Solutions
                </a>

                <a
                  routerLink="/content"
                  class="hero__product-cta qs-btn qs-btn--tiny qs-btn--secondary">

                  View content catalog
                </a>
              </div>
            </div>
          </div>

          <div class="hero__product">
            <img
              class="hero__product-img"
              src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/Group_618_1_62ae0a9f34.webp"
              alt="A photo of a person holding a mobile device with the QuickSeries QuickConnect app open."
              width="178"
              height="288"
              loading="lazy">

            <div class="hero__product-info">
              <p class="hero__product-title">App Solutions</p>
              <p class="hero__product-desc">
                Our apps take your message mobile, enabling you to reach
                everybody in your community or workforce.
              </p>

              <div class="hero__product-ctas">
                <a
                  routerLink="/products/quickconnect-app-solutions"
                  class="hero__product-cta qs-btn qs-btn--tiny qs-btn--primary">

                  Learn about App Solutions
                </a>

                <a
                  routerLink="/contact-us"
                  class="hero__product-cta qs-btn qs-btn--tiny qs-btn--secondary">

                  Book a demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-5 hero__right">
        <div class="home__img-container">
          <img
            class="home__image home__image--circle"
            src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/Ellipse_17_bdc6a35aaf.webp"
            alt="Grey circle"
            width="1372"
            height="1372">

          <div class="home__video-container">
            <div class="home__video-mask">
              <video
                class="home__video"
                loop="loop" autoplay="true" muted="true" playsinline="true"
                poster="https://qs-website-dev2.s3.us-east-2.amazonaws.com/poster_253e2ac364.webp"
                #videoPlayer>

                <source src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/home_HERO_app_600_9b7d0e39fd.webm" type="video/webm">
                <source src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/home_HERO_app_600_825d12cfda.mp4" type="video/mp4">
              </video>
            </div>
          </div>

          <img
            class="home__image home__image--guide"
            src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/p_Guide_open_1_0feabbf069.webp"
            alt="An opened QuickSeries pocket guide."
            width="638"
            height="1676">
        </div>
      </div>
    </div>

    <div class="customer-icons">
      <h2 class="customer-icons__title">
        Trusted by government agencies, military organizations, and top enterprises across all industries
      </h2>

      <ul class="no-list row align-items-center">
        @for (icon of content$().data?.attributes?.heroClients?.data; track icon.attributes.url) {
          <li class="col-3">
            <ng-template #logo>
              <img
                class="customer-icons__icon"
                loading="lazy"
                [src]="icon.attributes.url"
                [alt]="icon.attributes.alternativeText"
                [width]="icon.attributes.width"
                [height]="icon.attributes.height">
            </ng-template>

            @if (icon.attributes.caption) {
              <a
                target="_blank"
                rel="noopener noreferrer"
                [href]="icon.attributes.caption">

                <ng-container *ngTemplateOutlet="logo" />
              </a>
            } @else {
              <ng-container *ngTemplateOutlet="logo" />
            }
          </li>
        }
      </ul>
    </div>
  </div>
</section>

<app-sub-hero-section [content]="quickConnectSectionContent" />

<app-custom-section
  id="topics"
  class="print-section"
  [content]="printProductSectionContent">

  <div class="row">
    <div class="col col-sm-12">
      <h2 class="section__title">
        {{ content$().data?.attributes?.printTitle }}
      </h2>

      <div
        class="section__description section__description--no-columns"
        [innerHTML]="content$().data?.attributes?.printDescription | safe:'bypassSecurityTrustHtml'">
      </div>

      <div class="section__cta">
        @for (cta of content$().data?.attributes?.printCta; track cta.id) {
          <app-cta [cta]="cta" />
        }
      </div>

      <app-formats [color]="sectionColors.DARK_BLUE" />
    </div>
  </div>
</app-custom-section>

<app-content-categories-section
  [industries$]="industries$()"
  [militaries$]="militaries$()"
  [guides$]="guides$()" />

<app-custom-section [content]="newsLetterSectionContent">
  <div class="row">
    <div class="col-12 col-sm-6">
      <h2 class="section__title">
        {{ content$().data?.attributes?.newsletterTitle }}
      </h2>

      <div
        class="section__description section__description--no-columns"
        [innerHTML]="content$().data?.attributes?.newsletterDescription | safe:'bypassSecurityTrustHtml'">
      </div>

      <div class="section__cta">
        @for (cta of newsLetterCtas; track cta.id) {
          <app-cta [cta]="cta" />
        }
      </div>
    </div>

    <div class="col-12 col-sm-6 newsletter">
      <h3 class="newsletter__title">
        {{ content$().data?.attributes?.newsletterFormTitle }}
      </h3>

      <div
        class="newsletter__description"
        [innerHTML]="content$().data?.attributes?.newsletterFormDescription | safe:'bypassSecurityTrustHtml'">
      </div>

      <form
        class="qs-form"
        novalidate
        autocomplete="on"
        [formGroup]="form"
        (ngSubmit)="submit()">

        <label
          class="qs-form__label"
          for="email-address">

          Email address
        </label>

        <input
          type="email"
          id="email-address"
          name="email"
          placeholder="email@domain.com"
          formControlName="email">

        @if (email?.errors?.['required'] && (email?.touched || email?.dirty)) {
          <p class="qs-form__required qs-form__error">
            This field is required.
          </p>
        }

        @if (email?.errors?.['email'] && (email?.touched || email?.dirty)) {
          <p class="qs-form__required qs-form__error">
            Enter a valid email address.
          </p>
        }

        @if (isSuccessfullySubmitted !== null) {
          <app-alert-box
            [type]="isSuccessfullySubmitted ? alertBoxType.SUCCESS : alertBoxType.ERROR"
            [config]="alertBoxConfig"
            (closeBox)="dismissAlertBox()" />
        }

        <button
          type="submit"
          class="newsletter__submit qs-btn qs-btn--primary"
          [disabled]="!form.valid">

          Subscribe
        </button>
      </form>
    </div>
  </div>
</app-custom-section>

<app-contact-us [color]="sectionColors.WHITE_BLUE" />
