<ul class="print-solutions-list">
  @for (product of products; track product.type) {
    <li>
      <a
        class="print-solutions-list__achor"
        [routerLink]="product.routerLink"
        [routerLinkActive]="'print-solutions-list__achor--active'">

        <img
          class="print-solutions-list__image"
          [src]="product.image.data?.attributes?.url"
          [alt]="product.image.data?.attributes?.alternativeText"
          [width]="product.image.data?.attributes?.width"
          [height]="product.image.data?.attributes?.height">

        <div class="print-solutions-list__info">
          <p class="print-solutions-list__title">
            {{ product.name }}
          </p>

          <p class="print-solutions-list__desc">
            {{ product.description }}
          </p>
        </div>
      </a>
    </li>
  }
</ul>
