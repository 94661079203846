import { Component, HostListener, Input, inject } from '@angular/core';
import { ISubMenuLink } from './sub-menu.interface';
import { MainNavigationService } from '../main-navigation.service';
import { ESubNav } from '../main-navigation.interface';
import { EButtonColors } from '../../sections/section.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrl: './sub-menu.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ]
})
export class SubMenuComponent {
  protected readonly _mainNavigationService: MainNavigationService = inject(MainNavigationService);

  @Input({ required: true }) public title!: string;
  @Input({ required: true }) public links!: ISubMenuLink[];
  @Input({ required: true }) public type!: ESubNav;

  public readonly buttonType: typeof EButtonColors = EButtonColors;

  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    if (this._mainNavigationService.subMenuState$() !== null) {
      this.toggleSubNav(null);
    }

    if (this._mainNavigationService.mainMenuState$()) {
      this.closeMainNav();
    }
  }

  public readonly ESubNav: typeof ESubNav = ESubNav;

  public toggleSubNav(type: ESubNav | null): void {
    this._mainNavigationService.toggleSubNav(type);
  }

  public closeMainNav(): void {
    this._mainNavigationService.toggleMainMenu(true);
    this.toggleSubNav(null);
  }
}
