<div class="sub-menu-app-solutions">
  <div class="row">
    <div class="col-12 col-md-3 sub-menu-app-solutions__section">
      <h2 class="sub-menu-app-solutions__sub-title">
        About the Quickconnect App Solutions
      </h2>

      <ul class="no-list sub-menu-app-solutions__features">
        @for (link of quickConnectLinks; track $index) {
          <li>
            <a
              class="sub-menu-app-solutions__link"
              [routerLink]="link.routerLink"
              [routerLinkActive]="'sub-menu-app-solutions__link--is-active'"
              (click)="closeNavigation()">

              <span class="sub-menu-app-solutions__feature-icon qs-icon qs-icon--{{ link.icon }}"></span>

              {{ link.label }}
            </a>
          </li>
        }
      </ul>
    </div>

    <div class="col-12 col-md-3 sub-menu-app-solutions__section">
      <h2 class="sub-menu-app-solutions__sub-title">
        Our brands
      </h2>

      <ul class="no-list sub-menu-app-solutions__features">
        @for (link of quickConnectBrands; track $index) {
          <li>
            <a
              class="sub-menu-app-solutions__link"
              [routerLink]="link.routerLink"
              [routerLinkActive]="'sub-menu-app-solutions__link--is-active'"
              (click)="closeNavigation()">

              {{ link.label }}
            </a>
          </li>
        }
      </ul>
    </div>

    <div class="col-12 col-md-6 sub-menu-app-solutions__section">
      <h2 class="sub-menu-app-solutions__sub-title">
        User-Friendly, Feature-Rich App Technology
      </h2>

      <ul class="no-list sub-menu-app-solutions__selling-points">
        @for (point of sellingPoints; track $index) {
          <li class="sub-menu-app-solutions__selling-point">
            <img
              loading="lazy"
              class="sub-menu-app-solutions__selling-point-image"
              [src]="point.image.data.attributes.url"
              [alt]="point.image.data.attributes.alternativeText"
              [width]="point.image.data.attributes.width"
              [height]="point.image.data.attributes.height">

            <p
              class="sub-menu-app-solutions__selling-points-text"
              [innerHTML]="point.text">
            </p>
          </li>
        }
      </ul>
    </div>
  </div>
</div>
