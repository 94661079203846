import { ChangeDetectorRef, Component, PLATFORM_ID, WritableSignal, computed, effect, inject } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { ESubNav, INavLink } from './main-navigation.interface';
import { MainNavigationService } from './main-navigation.service';
import { ISubMenuLink } from './sub-menu/sub-menu.interface';
import { CommonModule, KeyValue, isPlatformBrowser } from '@angular/common';
import { ESortOrder } from '../search-bar/search-bar.interface';
import { EButtonColors } from '../sections/section.interface';
import { SearchModalService } from '../search-modal/search-modal.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { SubMenuWhiteboardVideoComponent } from './sub-menu/sub-menu-whiteboard-video/sub-menu-whiteboard-video.component';
import { SubMenuQuickblogComponent } from './sub-menu/sub-menu-quickblog/sub-menu-quickblog.component';
import { SubMenuPrintSolutionsComponent } from './sub-menu/sub-menu-print-solutions/sub-menu-print-solutions.component';
import { SubMenuContentComponent } from './sub-menu/sub-menu-content/sub-menu-content.component';
import { SubMenuAppSolutionsComponent } from './sub-menu/sub-menu-app-solutions/sub-menu-app-solutions.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { TGoogleCaptchaAssessment } from '../search-modal/search-modal.interface';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SearchInputComponent,
    SubMenuComponent,
    SubMenuAppSolutionsComponent,
    SubMenuContentComponent,
    SubMenuPrintSolutionsComponent,
    SubMenuQuickblogComponent,
    SubMenuWhiteboardVideoComponent,
  ],
})
export class MainNavigationComponent {
  protected readonly _router: Router = inject(Router);
  protected readonly _mainNavService: MainNavigationService = inject(MainNavigationService);
  protected readonly _ref: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);
  protected readonly _platformId: object = inject(PLATFORM_ID);

  private readonly _routerEvents$ = toSignal(this._router.events);

  public readonly ESubNav: typeof ESubNav = ESubNav;

  public activeSubMenu: ESubNav | null = null;
  public mainNavLinks: { [key: string]: INavLink } = {
    content: {
      type: this.ESubNav.CONTENT,
      label: 'Content',
      subMenu: true,
      isOpen: false,
      path: '/content',
      order: 1,
    },

    printSolutions: {
      type: this.ESubNav.PRINT_SOLUTIONS,
      label: 'Print Solutions',
      subMenu: true,
      isOpen: false,
      path: '/products/print-solutions-outreach-material',
      order: 2,
    },

    quickConnectAppSolutions: {
      type: this.ESubNav.APP_SOLUTIONS,
      label: 'QuickConnect App Solutions',
      subMenu: true,
      isOpen: false,
      path: '/products/quickconnect-app-solutions',
      order: 3,
    },

    whiteboardVideos: {
      type: this.ESubNav.WHITEBOARD_VIDEOS,
      label: 'Whiteboard Videos',
      subMenu: true,
      isOpen: false,
      path: '/products/whiteboard-video-creation',
      order: 4,
    },

    quickBlog: {
      type: this.ESubNav.QUICKBLOG,
      label: 'QuickBlog',
      subMenu: true,
      isOpen: false,
      path: '/quick-blog-infographics-trends',
      order: 5,
    },

    aboutUs: {
      label: 'About Us',
      subMenu: false,
      routerLink: '/about-quickseries',
      order: 6,
    },
  };

  public contentNav: ISubMenuLink[] = [
    {
      label: 'View all content',
      routerLink: '/content',
      queryParams: { sortOrder: ESortOrder.FEATURED },
    },
  ];

  public printSolutionsNav: ISubMenuLink[] = [
    {
      label: 'Learn about Print Solutions',
      routerLink: '/products/print-solutions-outreach-material',
    },
    {
      label: 'GSA info',
      routerLink: '/quickseries-gsa-schedule',
      buttonType: EButtonColors.SECONDARY,
    },
  ];

  public appSolutionsNav: ISubMenuLink[] = [
    {
      label: 'Learn about QuickConnect App Solutions',
      routerLink: '/products/quickconnect-app-solutions',
    },
    {
      label: 'Book a demo',
      routerLink: '/contact-us',
      buttonType: EButtonColors.SECONDARY,
    },
  ];

  public whiteboardVideoNav: ISubMenuLink[] = [
    {
      label: 'Learn about Whiteboard Videos',
      routerLink: '/products/whiteboard-video-creation',
    },
    {
      label: 'Download brochure',
      href: 'https://qs-website-dev2.s3.us-east-2.amazonaws.com/Whiteboard_Video_White_Paper_06b87f05c8.pdf',
      download: true,
      buttonType: EButtonColors.SECONDARY,
    },
  ];

  public quickBlogNav: ISubMenuLink[] = [
    {
      label: 'View all articles',
      routerLink: '/quick-blog-infographics-trends',
    },
  ];

  public navIsOpen$: WritableSignal<boolean> = this._mainNavService.mainMenuState$

  constructor() {
    computed(() => {
      this._handleEvents(this._routerEvents$());
    });

    effect(() => {
      this._handleSubMenuState(this._mainNavService.subMenuState$());
    });
  }

  public sortMainNavLinks = (a: KeyValue<string,INavLink>, b: KeyValue<string,INavLink>): number => {
    return a.value.order && b.value.order
      ? a.value.order < b.value.order
        ? -1
        : (b.value.order < a.value.order ? 1 : 0)
      : 0;
  }

  private _clearSubMenuState(): void {
    for (const key in this.mainNavLinks) {
      if (Object.prototype.hasOwnProperty.call(this.mainNavLinks, key) && this.mainNavLinks[key].isOpen) {
        this.mainNavLinks[key].isOpen = false;
      }
    }
  }

  private _handleSubMenuState(state: string | null): void {
    this._clearSubMenuState();

    if (state) {
      this.mainNavLinks[state].isOpen = !this.mainNavLinks[state].isOpen;
    }
  }

  public toggleNavigation(open: boolean): void {
    this._mainNavService.toggleMainMenu(open);
  }

  public toggleSubNav(type: string | null): void {
    this._mainNavService.toggleSubNav(type);
  }

  private _handleEvents(event?: Event): void {
    if (event instanceof NavigationStart) {
      this.toggleSubNav(null);
    }

    if (event instanceof NavigationEnd) {
      this._ref.detectChanges();
    }
  }

  public isCurrentPath(link: INavLink): boolean {
    if (!isPlatformBrowser(this._platformId)) {
      return false;
    }

    return (<any>window).location.pathname.match(link.path)?.length > 0;
  }

  public async openSearchModal() {
    const captchaAssessment: TGoogleCaptchaAssessment = await this._searchModalService.reCaptcha();

    if (captchaAssessment.riskAnalysis.score > 0.3) {
      this._searchModalService.toggleSearchModal(true);
      return;
    }

    this._searchModalService.toggleCaptchaModal(true);
  }
}
