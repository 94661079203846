import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IBlog } from 'src/app/blog/blog.interface';
import { BlogService } from 'src/app/blog/blog.service';

@Injectable({ providedIn: 'root' })
export class BlogRedirectService {
  protected readonly _blogService: BlogService = inject(BlogService);

  public getBlog(slug: string): Observable<IBlog> {
    return this._blogService.getBlog(slug);
  }
}
