import { Component, OnInit, Renderer2, WritableSignal, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { SeoService } from './core/seo/seo.service';
import { CommonModule, DOCUMENT, ViewportScroller } from '@angular/common';
import { GoogleAnalyticsService } from './core/google-analytics/google-analytics.service';
import { SearchModalService } from './components/search-modal/search-modal.service';
import { ErrorModule } from './error/error.module';
import { HomeModule } from './home/home.module';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { CaptchaModalComponent } from './components/search-modal/captcha-modal/captcha-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    // App modules
    HomeModule,
    ErrorModule,
    // Components
    MainNavigationComponent,
    FooterComponent,
    SearchModalComponent,
    CaptchaModalComponent,
  ],
})
export class AppComponent implements OnInit {
  protected readonly _router: Router = inject(Router);
  protected readonly _meta: Meta = inject(Meta);
  protected readonly _seoService: SeoService = inject(SeoService);
  protected readonly _renderer: Renderer2 = inject(Renderer2);
  protected readonly _analyticsService: GoogleAnalyticsService = inject(GoogleAnalyticsService);
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);
  protected readonly _titleService: Title = inject(Title);
  protected readonly _document: Document = inject(DOCUMENT);
  protected readonly _viewportScroller: ViewportScroller = inject(ViewportScroller);

  public searchModalIsOpen: WritableSignal<boolean> = this._searchModalService.searchModalIsOpen$;
  public captchaModalIsOpen: WritableSignal<boolean> = this._searchModalService.captchaModalIsOpen$;

  constructor() {
    this._viewportScroller.setOffset([0, 100]);
  }

  public ngOnInit(): void {
    this._meta.addTags([{ name: 'og:site_name', content: 'QuickSeries' }]);

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._seoService.destroySeoTags();
        this._seoService.destroyStructuredData();
      }

      if (event instanceof NavigationEnd) {
        this._analyticsService.event('page_view', {
          page_title: this._titleService.getTitle(),
          page_path: event.urlAfterRedirects,
          page_location: this._document.location.href
        });
      }
    });
  }
}
