import { IContent } from '../../content/content.interface';

export interface INavLink {
  label: string;
  routerLink?: string;
  subMenu: boolean;
  type?: ESubNav;
  icon?: string;
  path?: string;
  isOpen?: boolean;
  order?: number;
}

export enum ESubNav {
  CONTENT = 'content',
  APP_SOLUTIONS = 'app-solutions',
  PRINT_SOLUTIONS = 'print-solutions-outreach-material',
  WHITEBOARD_VIDEOS = 'whiteboard-videos',
  QUICKBLOG = 'quick-blog-infographics-trends',
}

export interface IContentMenuItem {
  name: string;
  uuid: string;
  slug: string;
  contents: IContent[];
}
