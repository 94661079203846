<div
  class="sub-menu"
  role="menu">

  <div class="container-lg">
    <header class="sub-menu__header">
      <button
        type="button"
        class="sub-menu__back qs-btn qs-btn--tiny qs-btn--text-only qs-btn--icon qs-btn--icon-arrow-backward d-block d-md-none"
        tabindex="-1"
        (click)="toggleSubNav(null)">

        Back
      </button>

      <p class="sub-menu__title">
        {{ title }}
      </p>

      <div class="sub-menu__links">
        @for (link of links; track link.label) {
          @if (link.routerLink) {
            <a
              class="sub-menu__link qs-btn qs-btn--{{ link.buttonType || buttonType.PRIMARY }}"
              [routerLink]="link.routerLink"
              [queryParams]="link.queryParams"
              [title]="link.label"
              (click)="closeMainNav()">

              {{ link.label }}
            </a>
          } @else if (link.href) {
            <a
              class="sub-menu__link qs-btn qs-btn--{{ link.buttonType || buttonType.PRIMARY }}"
              target="_blank"
              [href]="link.href"
              [attr.download]="link.download"
              [title]="link.label"
              (click)="closeMainNav()">

              {{ link.label }}
            </a>
          }
        }
      </div>
    </header>

    <div class="sub-menu__desc">
      <ng-content
        select="[description]"
        class="sub-menu__desc">
      </ng-content>
    </div>

    <ng-content />
  </div>
</div>
