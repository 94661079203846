import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainNavigationService {
  public mainMenuState$ = signal<boolean>(false);
  public subMenuState$ = signal<string | null>(null);

  public toggleMainMenu(isOpen: boolean): void {
    this.mainMenuState$.set(!isOpen);
  }

  public toggleSubNav(type: string | null): void {
    if (this.subMenuState$() === type) {
      this.subMenuState$.set(null);
      return;
    }

    this.subMenuState$.set(type)
  }
}
