import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IBlog } from 'src/app/blog/blog.interface';
import { BlogService } from 'src/app/blog/blog.service';
import { HelperFunctionsService } from 'src/app/core/helper-functions/helper-functions.service';
import { IDataPayloadMultiple } from 'src/app/core/http/http.interface';
import { MainNavigationService } from '../../main-navigation.service';
import { RouterModule } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-sub-menu-quickblog',
  templateUrl: './sub-menu-quickblog.component.html',
  styleUrl: './sub-menu-quickblog.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
  ],
})
export class SubMenuQuickblogComponent implements OnInit {
  protected readonly _blogService: BlogService = inject(BlogService);
  protected readonly _helperFunctions: HelperFunctionsService = inject(HelperFunctionsService);
  protected readonly _mainNavigationService: MainNavigationService = inject(MainNavigationService);

  public blogs$?: Observable<IDataPayloadMultiple<IBlog>>;

  public ngOnInit(): void {
    this.blogs$ = this._blogService.getBlogs(1, 6);
  }

  public truncateDescription(body: string): string {
    return this._helperFunctions.truncateText(body, 12);
  }

  public closeNavigation(): void {
    this._mainNavigationService.toggleMainMenu(true);
    this._mainNavigationService.toggleSubNav(null);
  }
}
