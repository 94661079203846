<div class="sub-menu-whiteboard-video">
  <div class="row">
    <div class="col-12 col-sm-6 col-md-5">
      <p class="sub-menu-whiteboard-video__label">
        Create an immersive experience to captivate your audience
      </p>

      <ul class="no-list sub-menu-whiteboard-video__list">
        <li class="no-list sub-menu-whiteboard-video__list-item">
          <span class="sub-menu-whiteboard-video__icon sub-menu-whiteboard-video__icon-boost"></span>
          <p class="sub-menu-whiteboard-video__small-desc">
            Boost interest at meetings, training sessions, and trade shows.
          </p>
        </li>

        <li class="no-list sub-menu-whiteboard-video__list-item">
          <span class="sub-menu-whiteboard-video__icon sub-menu-whiteboard-video__icon-encourage"></span>
          <p class="sub-menu-whiteboard-video__small-desc">
            Encourage social media interactions with likes, shares, and comments.
          </p>
        </li>

        <li class="no-list sub-menu-whiteboard-video__list-item">
          <span class="sub-menu-whiteboard-video__icon sub-menu-whiteboard-video__icon-minute"></span>
          <p class="sub-menu-whiteboard-video__small-desc">
            With a pay-per-minute structure, your video can be as long or short as you need.
          </p>
        </li>
      </ul>
    </div>

    <div class="col-12 col-sm-6 col-md-7">
      <div class="sub-menu-whiteboard-video__video-container">
        <iframe
          class="sub-menu-whiteboard-video__video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/muA8mf4C7uA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowfullscreen
          loading="lazy">
        </iframe>
      </div>
    </div>
  </div>
</div>
