import { Component, OnInit, inject } from '@angular/core';
import { SearchModalService } from '../search-modal.service';
import { RouterModule } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/core/google-analytics/google-analytics.service';

@Component({
  selector: 'app-captcha-modal',
  templateUrl: './captcha-modal.component.html',
  styleUrl: './captcha-modal.component.scss',
  standalone: true,
  imports: [
    RouterModule,
  ],
})
export class CaptchaModalComponent implements OnInit {
  protected readonly _searchModalService: SearchModalService = inject(SearchModalService);
  protected readonly _googleAnalyticsService: GoogleAnalyticsService = inject(GoogleAnalyticsService);

  ngOnInit() {
    this._googleAnalyticsService.event('captcha');
  }

  closeModal() {
    this._searchModalService.toggleCaptchaModal(false);
  }
}
