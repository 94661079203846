import { APP_INITIALIZER, ApplicationConfig, inject, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { IsActiveMatchOptions, Router, TitleStrategy, provideRouter, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { PageTitleStrategy } from './app.title.strategy';
import { SubMenuContentService } from './components/main-navigation/sub-menu/sub-menu-content/sub-menu-content.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withViewTransitions({
        skipInitialTransition: true,
        onViewTransitionCreated: ({transition}) => {
          const router = inject(Router);
          const targetUrl = router.getCurrentNavigation()!.finalUrl!;
          // Skip the transition if the only thing
          // changing is the fragment and queryParams
          const config: IsActiveMatchOptions = {
            paths: 'exact',
            matrixParams: 'exact',
            fragment: 'ignored',
            queryParams: 'ignored',
          };

          if (router.isActive(targetUrl, config)) {
            transition.skipTransition();
          }
        },
      }),
    ),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
    ),
    provideExperimentalZonelessChangeDetection(),
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    // This initializes the sub-menu content service and prevents SEO from being
    // impacted and marked a "javascript rendering fail" by search engines.
    {
      provide: APP_INITIALIZER,
      useFactory: (subMenuContentService: SubMenuContentService) => () => {
        return new Promise<void>((resolve) => {
          forkJoin([
            subMenuContentService.getIndustries(),
            subMenuContentService.getMilitaries(),
          ]).subscribe(() => {
            resolve();
          });
        });
      },
      deps: [SubMenuContentService],
      multi: true,
    }
  ],
};
