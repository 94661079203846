import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IContentMenuItem } from '../../main-navigation.interface';
import { SubMenuContentService } from './sub-menu-content.service';
import { IDataPayloadMultiple } from '../../../../core/http/http.interface';
import { MainNavigationService } from '../../main-navigation.service';
import { IContent } from 'src/app/content/content.interface';
import { ESectionColor } from 'src/app/components/sections/section.interface';
import { ESortOrder } from 'src/app/components/search-bar/search-bar.interface';
import { RouterModule } from '@angular/router';
import { ContentTitleItemComponent } from 'src/app/components/content-title-item/content-title-item.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-sub-menu-content',
  templateUrl: './sub-menu-content.component.html',
  styleUrl: './sub-menu-content.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    ContentTitleItemComponent,
  ],
})
export class SubMenuContentComponent implements OnInit {
  protected readonly _subMenuContentService: SubMenuContentService = inject(SubMenuContentService);
  protected readonly _mainNavigationService: MainNavigationService = inject(MainNavigationService);

  public readonly color: typeof ESectionColor = ESectionColor;
  public readonly sortOrder: typeof ESortOrder = ESortOrder;

  public industries$?: Observable<IDataPayloadMultiple<IContentMenuItem>>;
  public militaries$?: Observable<IDataPayloadMultiple<IContentMenuItem>>;
  public guides$?: Observable<IDataPayloadMultiple<IContent>>;

  public ngOnInit(): void {
    this.industries$ = this._subMenuContentService.getIndustries();
    this.militaries$ = this._subMenuContentService.getMilitaries();
    this.guides$ = this._subMenuContentService.getNewGuides();
  }

  public closeNavigation(): void {
    this._mainNavigationService.toggleMainMenu(true);
    this._mainNavigationService.toggleSubNav(null);
  }
}
