<div class="footer">
  <div class="footer__container container-md">
    <div class="row">
      <div class="footer__header col-sm-12 col-md-2">
        <a
          routerLink="/"
          class="footer__logo">

          <img
            src="https://qs-website-dev2.s3.us-east-2.amazonaws.com/QS_Logo_Red_e287b3fd32.svg"
            alt="QuickSeries logo"
            width="70"
            height="40"
            loading="lazy">
        </a>

        <div class="footer__social-medias">
          <a
            class="footer__social-media footer__social-media--linkedin"
            href="https://www.linkedin.com/company/quickseries-publishing-inc-/mycompany/"
            target="_blank"
            rel="nofollow"
            title="QuickSeries LinkedIn page">

            LinkedIn
          </a>

          <a
            class="footer__social-media footer__social-media--facebook"
            href="https://www.facebook.com/profile.php?id=100069090406656"
            target="_blank"
            rel="nofollow"
            title="QuickSeries Facebook page">

            Facebook
          </a>
        </div>
      </div>

      <div class="col-sm-12 col-md-10">
        <div class="footer__links">
          @for (section of footerLinks$(); track section) {
            <div class="footer__link-container">
              <p class="footer__link-container-title">
                {{ section.label }}
              </p>

              <nav>
                @for (link of section.links; track link) {
                  @if (link.routerLink) {
                    <a
                      class="footer__link"
                      [routerLink]="link.routerLink">

                      {{ link.label }}
                    </a>
                  }

                  @if (link.href) {
                    <a
                      class="footer__link"
                      target="_blank"
                      [href]="link.href | safe:'bypassSecurityTrustUrl'">

                      {{ link.label }}
                    </a>
                  }
                }
              </nav>
            </div>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="footer__copyright">
    <p class="footer__copyright-text">
      &copy; QuickSeries, Inc. {{ thisYear }}. Reliable Content. Innovative Delivery.
    </p>
  </div>
</div>
